@import "variables";

.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 6.5rem 6rem 2rem 6rem !important;
  transition: margin-left $transitionDuration;
}

.remove-layout-padding {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

@media (max-width: 1024px) { /* Tablets */
  .layout-main-container {
    padding: 6.5rem 4rem 2rem 4rem !important;
  }
  .remove-layout-padding {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
}

@media (max-width: 768px) { /* Móviles grandes */
  .layout-main-container {
    padding: 6.5rem 2rem 2rem 2rem !important;
  }
  .remove-layout-padding {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
}

@media (max-width: 480px) { /* Móviles pequeños */
  .layout-main-container {
    padding: 6.5rem 1rem 2rem 1rem !important;
  }
  .remove-layout-padding {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
