$primary050: #fdfefe !default;
$primary100: #ebedf8 !default;
$primary200: #d7dcf3 !default;
$primary300: #c2caf0 !default;
$primary400: #abb8ed !default;
$primary500: #93a4ec !default;
$primary600: #607bee !default;
$primary700: #274ff5 !default;
$primary800: #1638a8 !default;
$primary900: #0a1e57 !default;
$primary950: #000103 !default;

$primaryColor: $primary700 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #F5F3FF !default;
$highlightTextColor: $primary700 !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import 'variables';
@import 'fonts';
@import '../theme-base/components';
@import 'extensions';

.p-dialog .p-inputtextarea {
	width: 100%;
	min-height: 100px; /* ajusta según necesites */
	resize: vertical;
}

.p-float-label input:focus~label, .p-float-label input.p-filled~label, .p-float-label textarea:focus~label, .p-float-label textarea.p-filled~label, .p-float-label .p-inputwrapper-focus~label, .p-float-label .p-inputwrapper-filled~label{
	left: 0 !important;
}
.pi-rotate-90 {
	transform: rotate(270deg);
}
.dropdown-divider{
	margin:0 -1.25rem;
}
.custom-confirmation-modal .p-dialog-content {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	.footer button{
		padding: .5rem 1rem;
		width: 100%;
	}
}
.p-stepper {
	.p-stepper-header.p-disabled {
		opacity: 1;
	}
	.p-stepper-header {
		padding: 0;
	}
	.p-stepper-separator {
		background: none;
		margin-inline-start: 0;
	}

	.custom-separator {
		width: 100%;
		height: 3px;
		background-color: var(--neutral-grey-400);
		margin: 0 auto;
	}

	.custom-separator-doted {
		width: 100%;
		height: 3px;
		background: repeating-linear-gradient(
										to right,
										transparent 0px,
										transparent 3px,
										var(--neutral-grey-400) 3px,
										var(--neutral-grey-400) 6px
		);
		margin: 0 auto;
	}

	.custom-separator-doted.p-stepper-done{
		background: repeating-linear-gradient(
										to right,
										transparent 0px,
										transparent 3px,
										var(--primary-color) 3px,
										var(--primary-color) 6px
		);
	}
}
