/* 0. Primitives */
:root {
    /* Mywork */
    /* color */
    --base-black: #0f0f0f;
    --base-white: #ffffff;
    --brand-complementary-0: #ca3e42;
    --brand-complementary-1: #c43755;
    --brand-complementary-2: #4f3f70;
    --brand-complementary-3: #19588b;
    --brand-complementary-4: #19588b;
    --brand-primary-100: #ebedf8;
    --brand-primary-200: #d7dcf3;
    --brand-primary-300: #c2caf0;
    --brand-primary-400: #abb8ed;
    --brand-primary-50: #fdfefe;
    --brand-primary-500: #93a4ec;
    --brand-primary-600: #607bee;
    --brand-primary-700: #274ff5;
    --brand-primary-800: #1638a8;
    --brand-primary-900: #0a1e57;
    --brand-primary-950: #000103;
    --brand-secondary-100: #d6ca9e;
    --neutral-color-950: #0f0f0f;
    --neutral-grey-100: #eff3f6;
    --neutral-grey-200: #d5d9db;
    --neutral-grey-300: #babdc0;
    --neutral-grey-400: #a1a4a5;
    --neutral-grey-50: #fbfcfd;
    --neutral-grey-500: #86888a;
    --neutral-grey-600: #454545;
    --neutral-grey-700: #3c3d3e;
    --neutral-grey-800: #333333;
    --neutral-grey-900: #1e1e1e;
    --opacity-black-opacity-black-25: #1e1e1e40;
    --opacity-black-opacity-black-medium-70: #1818188f;
    --opacity-white-opacity-white--70: #ffffffb2;
    --opacity-white-opacity-white--20: #ffffff33;
    --opacity-white-opacity-white-medium---50: #fbfcfdb2;
    --semantic-info-info-100: #eff4fe;
    --semantic-info-info-700: #276ef1;
    --semantic-negative-negative-100: #fff0ee;
    --semantic-negative-negative-700: #de1135;
    --semantic-positive-positive-100: #eaf6ed;
    --semantic-positive-positive-700: #09b83a;
    --semantic-warning-warning-100: #fdf2dc;
    --semantic-warning-warning-700: #f6bc2f;
    /* number */
    --size-half: 0.125rem;
    --size-1: 0.25rem;
    --size-1half: 0.375rem;
    --size-2: 0.5rem;
    --size-2half: 0.625rem;
    --size-3: 0.75rem;
    --size-4: 1rem;
    --size-5: 1.25rem;
    --size-6: 1.5rem;
    --size-7: 1.75rem;
    --size-8: 2rem;
    --size-9: 2.25rem;
    --size-10: 2.5rem;
    --size-11: 2.75rem;
    --size-12: 3rem;
    --size-22: 5.5rem;
}
/* 1. Tokens Colors */
:root {
    --background-accent: var(--brand-primary-700);
    --background-base: var(--base-black);
    --background-brand: var(--brand-primary-950);
    --background-disable: var(--neutral-grey-900);
    --background-info: #317cff33;
    --background-opacity-primary: var(--opacity-black-opacity-black-25);
    --background-opacity-base: var(--opacity-black-opacity-black-medium-70);
    --background-primary: var(--neutral-grey-900);
    --background-secondary: var(--neutral-grey-800);
    --background-tertiary: var(--neutral-grey-800);
    --background-button-primary-active: var(--brand-primary-700);
    --background-button-primary-default: var(--brand-primary-800);
    --background-button-primary-hover: var(--brand-primary-600);
    --background-button-secondary-active: var(--background-accent);
    --background-button-secondary-default: var(--neutral-grey-800);
    --background-button-secondary-hover: var(--neutral-grey-700);
    --background-button-tertiary-default: var(--neutral-grey-700);
    --border-error: var(--semantic-negative-negative-700);
    --border-accent: var(--brand-primary-700);
    --border-active: var(--brand-primary-400);
    --border-base: var(--neutral-color-950);
    --border-disable: var(--neutral-grey-800);
    --border-hover: var(--brand-primary-800);
    --border-info: var(--content-info);
    --border-opacity: var(--opacity-black-opacity-black-25);
    --border-primary: var(--neutral-grey-800);
    --border-secondary: var(--neutral-grey-600);
    --content-accent: var(--brand-primary-700);
    --content-disable: var(--neutral-grey-600);
    --content-hover: var(--brand-primary-800);
    --content-info: #538bf4;
    --content-negative: #fd6f6f;
    --content-no-change: var(--base-white);
    --content-positive: var(--semantic-positive-positive-700);
    --content-primary: var(--neutral-grey-50);
    --content-secondary: var(--neutral-grey-400);
    --content-tertiary: var(--neutral-grey-500);
    --content-warning: var(--semantic-warning-warning-700);
}
/* 3. Spacing */
:root {
    --button-vertical-padding: var(--size-1);
    --button-gap: var(--size-2);
    --button-corner-radius: var(--size-2half);
    --button-horizontal-padding: var(--size-3);
    --button-icon-size: var(--size-4);
    --container-corner-radius: var(--size-2half);
}
