@import "variables";
@import "../theme/theme";

.card {
    background: var(--surface-card);
    border: 2px solid var(--surface-border);
    padding: 16px;
    border-radius: $cardRadius;
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 100px;
    }
}

.max-w-35rem {
    max-width: 35rem !important;
}

.scrollbar-hover {
    overflow: scroll;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background: var(--scrollbar-color);
            border-radius: 5px;
        }
    }
}

.text-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &.ellipsis-one {
        -webkit-line-clamp: 1;
    }

    &.ellipsis-two {
        -webkit-line-clamp: 2;
    }
}


.break-word {
	overflow-wrap: break-word;
}
