@import "variables";

.layout-footer {
	position: fixed;
	height: 5rem;
	z-index: 4;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 0 1rem;
	justify-content: space-between;
	align-items: center;
}
