@import "variables";

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: $scale;
}

body {
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--surface-ground);
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

.layout-wrapper {
  min-height: 100vh;
}

.custom-scrollbar {
	word-break: break-all;
	overflow-y: scroll;
	animation: background 5s infinite alternate;
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
	transition: scrollbar-color .3s;

	&:hover {
		scrollbar-color: #484848 transparent;
	}

	&:not(:hover)::-webkit-scrollbar-thumb {
		background: transparent;
	}

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 5px;
		transition: background 0.3s;
		background: rgba(0, 0, 0, .75);
	}
}

p-confirmdialog{
	.p-button-sm{
		padding: 0.5rem 1rem;
		width: 100%;
	}
}
